<template>
  <div class="main">
    <topheader lucency backWhite v-show="canvas" />
    <img :src="bg" v-if="!canvas" />
    <!-- 文字部分 -->
    <div class="content" v-if="!canvas">
      <div class="header">
        <div class="avatar">
          <img :src="headImg" />
        </div>
        <div class="name" v-text="name"></div>
        <p class="store">
          向你推荐
          <span v-text="title"></span>
        </p>
      </div>
      <div class="recommend">
        <!-- <p class="recommend_1st">不用错过的精品课程</p> -->
        <p class="recommend_2nd">邀请你和我一起成长</p>
      </div>
      <div class="qrcode_box">
        <div class="qrcode" id="qrcode" ref="qrcode"></div>
        <p>扫码识别，查看好课</p>
      </div>
    </div>
    <div id="recommend_canvas" v-else></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import { getOneClassinfo, getStuShareCode } from '@/api/classRing'
import { getAuthority } from '@/api/pay'
// import eventBus from '@/util/eventBus'
export default {
  name: 'recommend',
  data() {
    return {
      classId: this.$route.params.classId,
      userId: this.$route.params.userId,
      defaultHeader: require('@/assets/images/head_prole.png'),
      headImg: '',
      name: '',
      bg: '',
      baseUrl: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa3cbbb87523bdf64&redirect_uri=http%3a%2f%2fheying.hyclound.cn%2fclassDetails%2f${this.$route.params.classId}&response_type=code&scope=snsapi_base&state=`,
      content: '',
      title: '',
      recommendA: '', //一级推荐人
      canvas: false,
      timer: null, //定时器
      classInfo: {} // 班圈信息
    }
  },
  methods: {
    qrCode(url) {
      let qrcode = new QRCode('qrcode', {
        width: 114, //图像宽度
        height: 114, //图像高度
        colorDark: '#000000', //前景色
        colorLight: '#ffffff', //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.L //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      })
      qrcode.clear() //清除二维码
      qrcode.makeCode(url) //生成另一个新的二维码
    },
    canvasDom() {
      const target = document.getElementById('app')
      html2canvas(target, {
        allowTaint: false,
        useCORS: true
      }).then(canvas => {
        this.canvas = true
        const image = new Image()
        image.src = canvas.toDataURL('image/png')
        this.$nextTick(() => {
          document.getElementById('recommend_canvas').appendChild(image)
        })
      })
    },
    shareSetting(res) {
      let shareIcon = ''
      if (this.classInfo.data) {
        shareIcon = this.classInfo.data[0].pic
      } else {
        shareIcon = res.headurl
      }
      let params = {
        title: res.title,
        desc: res.describe ? res.describe : `${res.name} 邀您加入班圈`,
        link: `${location.origin}/classDetails/${this.classId}?state=${this.classId}_${this.userId}_${res.recommendA}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareIcon,
        success: () => {
          console.warn('成功分享：朋友/朋友圈', params)
        },
        fail: e => {
          console.error('分享失败：朋友/朋友圈', e)
        }
      }
      //需在用户可能点击分享按钮前就先调用
      wx.ready(() => {
        wx.updateAppMessageShareData(params) // 分享给朋友
        setTimeout(() => {
          wx.updateTimelineShareData(params) // 分享到朋友圈
        }, 300)
      })
    },
    // 定时监听 背景图+头像，如果都有值就转换成图片
    timerSetting() {
      this.timer = setInterval(() => {
        if (this.bg && this.headImg) {
          this.canvasDom()
          clearInterval(this.timer)
          this.timer = null
        }
      }, 500)
    },
    getRecommendInfo() {
      getStuShareCode(this.userId, this.classId).then(res => {
        this.recommendA = res.recommendA //一级推荐人
        this.name = res.name //姓名
        this.title = res.title //标题
        const state = `${this.classId}_${this.userId}_${
          res.recommendA ? res.recommendA : ''
        }`
        this.shareUrl = this.baseUrl + state
        this.qrCode(this.shareUrl)

        this.shareSetting(res)

        // 头像
        let head = new Image()
        head.src = res.headurl
        head.onload = () => {
          this.headImg = res.headurl //头像
        }
        head.onerror = () => {
          this.headImg = this.defaultHeader
        }
        // 背景图
        let img = new Image()
        img.src = res.pic
        img.onload = () => {
          this.bg = res.pic
        }
        img.onerror = () => {
          this.bg = require('@/assets/images/recommend_bg.png')
        }
      })
    }
  },
  created() {
    getAuthority()
  },
  async mounted() {
    try {
      // 这里只是为了获取班圈图片，没必要报错了就卡住
      this.classInfo = await getOneClassinfo(this.classId)
    } catch(e) {}
    this.getRecommendInfo() //获取用户推荐二维码信息
    this.timerSetting()
  },
  activated() {
    // ② 解决二维码分享跳转到班圈详情页无法返回到首页的问题
    this.$setgoindex()
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 100vh;
  background: #000000;
}
.main > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  display: block;
}

/* 文字部分 */
.main {
  box-sizing: border-box;
  position: relative;
  .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 9vw;
    .header {
      padding-top: 22vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      .avatar {
        width: 14vw;
        height: 14vw;
        border-radius: 50%;
        overflow: hidden;
        img {
          @include imgCover;
        }
      }
      .name {
        padding: 3vw 2vw 2vw 2vw;
        font-size: 4vw;
      }
      .store {
        // 向你推荐
        text-align: center;
        span {
        }
      }
    }
    .recommend {
      padding-top: 12vw;
      text-align: center;
      font-size: 9vw;
      color: #f0d8ab;
      .recommend_1st {
      }
      .recommend_2nd {
        padding-top: 1vw;
        font-size: 7vw;
      }
    }
    .qrcode_box {
      padding-top: 8vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /deep/.qrcode {
        width: 30vw;
        height: 30vw;
        border: 1px solid #f0d8ab;
        padding: 1.5vw;
        position: relative;
        img {
          @include wh(95%, 95%);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      p {
        padding: 1vw;
        color: #f0d8ab;
      }
    }
  }
  // 将html转换成img之后的盒子
  /deep/#recommend_canvas {
    @include wh(100%, 100%);
    img {
      @include imgCover;
    }
  }
}
</style>
